<template>
  <div class="gry-bg py-5">
    <div class="profile">
      <div class="container">
        <div class="row">
          <div class="col-xxl-4 col-xl-5 col-lg-6 col-md-8 mx-auto">
            <div class="card">
              <div class="text-center pt-4">
                <h1 class="h4 fw-600">
                  {{ $t('deng-lu-dao-nin-de-zhang-hu') }} </h1>
              </div>

              <div class="px-4 py-3 py-lg-4">
                <div class="">
                  <form class="form-default"  >

                    <div class="form-group phone-form-group">
                      <input type="text" class="form-control"  v-model="form.email" :placeholder="$t('dian-zi-you-jian')" >
                    </div>

                    <div class="form-group">
                        <input type="password" class="form-control" v-model="form.password" :placeholder="$t('mi-ma')" >
                    </div>

                    <div class="row mb-2">
                      <div class="col-6">
                          <label class="aiz-checkbox">
                              <input type="checkbox" name="remember" >
                              <span class=opacity-60>{{ $t('ji-de-wo') }}</span>
                              <span class="aiz-square-check"></span>
                          </label>
                      </div>
                      <div class="col-6 text-right">
                          <a class="text-reset opacity-60 fs-14" @click="toPath('forget')">{{ $t('wang-ji-mi-ma-0') }}</a>
                      </div>
                    </div>

                    <div class="mb-5">
                        <button type="button"  class="btn btn-primary btn-block fw-600" @click="doLogin">{{ $t('deng-lu') }}</button>
                    </div>
                  </form>
                </div>
                <div class="text-center">
                    <p class="text-muted mb-0">{{ $t('mei-you-zhang-hu') }}</p>
                    <a href="javascript: void(0)" @click="toPath('register')">{{ $t('xian-zai-zhu-ce') }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { login } from '@/api/login'
import {setToken, setUser, setUserType} from '@/utils/auth'
export default {
  data() {
    return {
      form: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    doLogin() {
      if (!this.form.email) {
        this.$toast(this.$t('qing-shu-ru-you-xiang'))
        return
      }
      if (!this.form.password) {
        this.$toast(this.$t('qing-shu-ru-mi-ma'))
        return
      }
      let form = new FormData()
      form.append('Email', this.form.email)
      form.append('Password', this.form.password)
      login(form).then(res => {
        if (res.code == 0) {
          setToken(res.data.token)
          let userType = res.data.attribute == 1 ? 'custom' : 'seller'
          setUserType(userType)
          this.$store.state.userType = userType
          setUser(this.form.email)
          let routeName = res.data.attribute == 1 ? 'dashboard' : 'sellerDashboard'
          setTimeout(() => {
            this.$store.state.isLogin = true
            this.$router.push({
              name: routeName
            })

          }, 200)
        } else {
          this.$toast.fail(res.msg)
        }
      })
      
    }
  }
}
</script>